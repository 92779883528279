.button {
  cursor:pointer;
  border-radius:30px;
  white-space:nowrap;
  user-select:none;
  position:relative;
  padding-bottom:2px;

  img {
    position:absolute;
    top:50%;
    transform:translate(0,-50%);
  }
  &.free {
    width:auto !important;
    padding-left: 35px;
    padding-right: 35px;
  }
  &.free2 {
    width:auto !important;
    padding-left: 28px;
    padding-right: 28px;
  }

  &[type=dark] {
    line-height:24px;
    font-size:12px;
    color: $text_grey;
    background:$super-light;
    padding-left: 15px;
    padding-right: 15px;
    @include hover {
      color:$text_dark;
      background:rgba($light_grey, 0.5);
    }
    @include active {
      transition:0s;
      background:$light_grey;
    }
  }
  &[type=decline],
  &[type=confirm] {
    line-height:28px;
    width:148px;
    text-align:center;
    font-size:16px;
    @include phone {
      width:100%;
      line-height:34px;
      font-size:19px;
    }
  }

  &[type=mini] {
    font-size:12px;
    line-height:19px;
    border: 2px solid $green;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 30px;

    @include hover {
      background:$green;
      color:white;
    }
    @include active {
      border-color: $dark_green;
      background:$dark_green;
      color:white;
    }
  }

  &[type=decline] {
    border: 2px solid $light_grey;
    @include hover {
      background:$light_grey;
      color:white;
    }
    @include active {
      border-color:$icon_grey;
      background:$icon_grey;
      color:white;
    }
  }
  &[type=confirm] {
    border: 2px solid $green;
    @include hover {
      background:$green;
      color:white;
    }
    @include active {
      border-color: $dark_green;
      background:$dark_green;
      color:white;
    }
  }
  &[type=more] {
    line-height: 127%;
    text-decoration:underline;
    padding-left:28px;
    svg {
      position:absolute;
      left:7px;
      width:12px;
      top:50%;
      transform:translate(0,-50%);
      transition:0.25s;
      rect {
        fill:$green;
        transition:0.25s;
      }
    }
    @include hover {
      color:$green;
    }
    @include active {
      transition:0s;
      color:$dark_green;
      svg rect {
        transition:0s;
        fill:$dark_green;
      }
    }
    &.less {
      svg rect:last-of-type {
        transform: scaleY(0);
        transform-origin: 0 50%;
      }
    }
  }
  &[type=download] {
    line-height:34px;
    padding:0 17px 0 35px;
    background:#F7F8FA;
    img, svg {
      position:absolute;
      top:50%;
      transform:translate(0,-50%);
      width:11.6px;
      left:15px;
      @include phone {
        width:13px;
        margin-right:5.5px;
        position:relative;
        left:0;
        top:0;
        transform:none;
      }
    }
    @include phone {
      line-height:40px;
      padding:0;
      font-size:19px;
      margin: 10px 10px;
      justify-content: center;
      display: inline-flex;
      width:calc(100% - 20px);
    }
    @include hover {
      background:$super-light;
    }
    @include active {
      background:$light_grey;
      svg path {
        transition:0s;
        fill:$dark_green;
      }
    }
  }
  &.popup-switcher {
    line-height:20px;
    background:$super-light;
    padding:19px 0 14px 72px;
    border-radius: 18px 18px 0 0;
    display:inline-block;
    font-size:16px;
    min-width:178px;
    padding-right: 30px;

    @include before {
      left:30px;
      width:30px;
      height:30px;
      opacity: 0.5;
      border-radius: 12px;
      background:white;
      margin-top:3px;
    }

    &.active {
      background:white;
      box-shadow: 0 0 8px rgba(79, 110, 149, 0.1);
      z-index:1;
      &:before {
        background:$super-light;
      }
    }

    @include phone {
      border-radius: 23px;
      min-width:auto;
      width:auto;
      line-height:34px;
      padding:0 18px 0 40px;
      box-shadow:none;
      &:before {
        left:5px;
        width:26px;
        height:26px;
        border-radius: 10px;
        margin-top:0;
      }
      &:after {
        left:11px;
        margin-top:0;
      }
      &.active {
        background:$super-light;
        &:before {
          background:white;
        }
      }
    }

    &[type=search] {
      @include after {
        left:38px;
        width:15px;
        height:15px;
        background:url(~@/assets/images/search.svg);
        background-size:100%;
        margin-top:3px;
      }
      @include phone {
        &:after {
          left:11px;
          margin-top:0;
        }
      }
    }

    &[type=star] {
      @include after {
        width:14px;
        height:13px;
        background:url(~@/assets/images/star.svg);
        background-size:100%;
        left:11px;
      }
    }

    &[type=get] {
      @include after {
        left:12px;
        margin-top:0;
        width:12px;
        height:14px;
        background:url(~@/assets/images/ar-down.svg);
        background-size:100%;
      }
    }

    &[type=letter] {
      @include after {
        left:38px;
        width:15px;
        height:12px;
        background:url(~@/assets/images/letter.svg);
        background-size:100%;
        margin-top:3px;
      }
      @include phone {
        &:after {
          left:11px;
          margin-top:0;
        }
      }
    }

    &[type=add] {
      @include after {
        left:38px;
        width:14px;
        height:14px;
        background:url(~@/assets/images/add.svg);
        background-size:100%;
        margin-top:3px;
      }
      @include phone {
        &:after {
          left:11px;
          margin-top:0;
        }
      }
    }

    &[type=question] {
      @include after {
        left:38px;
        width:14px;
        height:14px;
        background:url(~@/assets/images/question.svg);
        background-size:100%;
        margin-top:3px;
      }
      @include phone {
        &:after {
          left:11px;
          margin-top:0;
        }
      }
    }

    &[type=quality] {
      @include after {
        left:35px;
        width:20px;
        height:20px;
        background:url(~@/assets/images/quality.svg);
        background-size:100%;
        margin-top:3px;
      }
      @include phone {
        &:after {
          left:8px;
          margin-top:0;
        }
      }
    }

    &[type=qualityDocPkgs] {
      @include after {
        left:36px;
        width:18px;
        height:13px;
        background:url(~@/assets/images/qualityDocPkgs.svg);
        background-size:100%;
        margin-top:3px;
      }
      @include phone {
        &:after {
          left:9px;
          margin-top:0;
        }
      }
    }
  }
}

.phone-button {
  position:relative;
  padding-left:14.6%;
  color:$text_grey;
  font-size: 11px;
  background: $super-light;
  border-radius: 23px;
  line-height:32px;
  display:block;
  text-decoration:none;
  & + .phone-button {
    margin-left:14px;
  }
  &.home {
    @include after {
      left:12px;
      width: 23px;
      height:21px;
      background:url(~@/assets/images/home.svg);
      background-size:100%;
    }
  }
  &.phone {
    @include after {
      left:10px;
      width: 22px;
      height:22px;
      background:url(~@/assets/images/phone.svg);
      background-size:100%;
    }
  }
}

.column {
  .button + .button {
    margin-top:21px;
    @include phone {
      margin-top:9px;
    }
  }
}