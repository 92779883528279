* {
  box-sizing:border-box;
}
a, a:active, a:focus{
  outline: none
}
html {
  font-family: 'PT Sans', sans-serif;
  color:$text_dark;
  font-size:14px;
  line-height: 16px;
  height: 100%;
  background:#F5F7F9;
  @include phone {
    background:white;
  }
}
#app {
  overflow-y:scroll;
  & > div {
    height:100%;
  }
}
.scroll-content {
  height: 100%;
}
body, #app {
  height: 100%;
}
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page-content {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}

.wrapper {
  width:73.438%;
  max-width:1880px;
  margin:0 auto;
  @include desktop-low {
    width:calc(100% - 40px);
  }
  @include phone {
    width:calc(100% - 20px);
  }
}

.inline {
  width:100%;
  display:inline-flex;

  &[halign=between] {
    justify-content: space-between;
  }
  &[halign=center] {
    justify-content: center;
  }
  &[halign=right] {
    justify-content: flex-end;
  }
  &[valign=center] {
    align-items: center;
  }
  &[valign=bottom] {
    align-items: flex-end;
  }
  &[valign=top] {
    align-items: flex-start;
  }
  @include phone {
    &.mob-col {
      flex-direction:column;
    }
  }
}
.column {
  display:inline-flex;
  flex-direction:column;
  align-items: flex-start;
}
strong {
  font-weight:bold;
}
h1 {
  font-size: 24px;
  line-height: 111%;
  margin:0 0 14px;
  @include phone {
    font-size:18px;
    margin:0 0 17px;
  }
}
a {
  color:$text_dark;
}
.link {
  text-decoration:underline;
  cursor:pointer;
  user-select:none;
  &._clear {
    text-decoration:none;
  }
  @include hover {
    color:$green !important;
    &._reverse {
      color:$icon_grey !important;;
    }
  }
  @include active {
    color:$dark_green !important;
    &._reverse {
      color:$text_grey !important;;
    }
  }
}
.phone-only {
  display:none;
  @include phone {
    display:flex;
  }
}
.desktop-only {
  @include phone {
    display:none;
  }
}
.right {
  align-items:flex-end;
  text-align:right;
}
.bottom {
  display: flex;
  justify-content: flex-end;
}

.wrap {
  display:flex;
  flex-wrap:wrap;
}

.mob-col {
  @include phone {
    display:flex;
    flex-direction:column;
  }
}
[status=loading] {
  pointer-events:none;
  &:before {
    content:'';
    position:absolute;
    background:rgba(white, 0.6);
    left:0;
    top:0;
    width:100%;
    height:100%;
    border-radius: inherit;
    z-index:100;
  }
  &:after {
    content:'';
    position:absolute;
    background:url(~@/assets/images/loader.svg) center center no-repeat;
    background-size:100%;
    transform:translate(-50%,-50%);
    left:50%;
    top:50%;
    width:40px;
    height:40px;
    z-index:100;
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: translate(-50%,-50%) rotate(0deg);
    -o-transform: translate(-50%,-50%) rotate(0deg);
    transform: translate(-50%,-50%) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: translate(-50%,-50%) rotate(0deg);
    -moz-transform: translate(-50%,-50%) rotate(0deg);
    -webkit-transform: translate(-50%,-50%) rotate(0deg);
    -o-transform: translate(-50%,-50%) rotate(0deg);
    transform: translate(-50%,-50%) rotate(0deg);
  }
  to {
    -ms-transform: translate(-50%,-50%) rotate(360deg);
    -moz-transform: translate(-50%,-50%) rotate(360deg);
    -webkit-transform: translate(-50%,-50%) rotate(360deg);
    -o-transform: translate(-50%,-50%) rotate(360deg);
    transform: translate(-50%,-50%) rotate(360deg);
  }
}

.content {
  padding:8px 0 30px;
  @include phone {
    padding:10px 0 0;
  }
  & > h1 {
    margin-top:11px;
  }
  & > .inline {
    align-items: flex-start;
    & > .content-container {
      width:74.4%;

      @include tablet {
        width:66.66%;
      }

      @include phone {
        width:100%;
      }

      &.fullWidth {
          width:100%;
      }
      & > div {
        width:100%;
      }
    }
  }
  &-aside {
    & + .slider {
      margin-top:6px;
    }
  }
}

.scrollbar-thumb {
  background:$light_grey !important;
}

h1, h2, h3, h4 {
  font-weight:bold;
}
.pt-0 {
  padding-top: 0 !important;
}