.mobileMenu.mobile-popup {
  padding:0 0 30px 0 !important;
  .mobileMenu-header {
    padding:11px 16px 11px 20px;
    background:#F5F7F9;
    height:52px;
    .close {
      position:relative;
      top:initial;
      right:initial;
      width:19.26px;
      height:19.26px;
      &:before, &:after {
        width:26px;
        height:3px;
        border-radius: 3px;
        background:$icon_grey;
      }
    }
    p {
      font-size:10px;
      margin:0 15px 0 27px;
      width:calc(100% - 100px);
      line-height: 15px;
      max-height: 30px;
      overflow: hidden;
      display: block;
    }
    .logout {
      display: flex;
      top: 1px;
      right: 5px;
    }
  }
  .mobileMenu-item {
    &.router-link-exact-active, &.router-link-active {
      background:#F5F7F9;
    }
    .icon {
        width:69px;
        text-align:center;
        img {
          width:26px;
          &[type=quality] {
            width:27px;
          }
          &[type=claims] {
            width:27px;
          }
          &[type=price] {
            width:25px;
          }
          &[type=boomerang] {
            width:27px;
          }
          &[type=promotions] {
            width:19px;
          }
          &[type=support] {
            width:21px;
          }
          &[type=managers] {
            width:27px;
          }
        }
    }
    strong {
        width:calc(100% - 89px);
        padding:20px 0;
        line-height:18px;
    }
  }
  .mobileMenu-subitem {
      & > * {
        display:block;
        margin-bottom:20px;
        line-height:18px;
        padding-left:89px;
      }
  }
}