.popup[type=searchInvoice] {
  .popup-content {
    min-height: 300px;
  }

  .table-container + .column {
    margin-top:26px;
    padding-bottom: 3px;
    @include phone {
      margin:0;
      .button {
        width:100% !important;
      }
    }
  }

  @include phone {
    padding-bottom:0 !important;
    .inline[valign=bottom] {
      align-items:flex-start;
    }
    .range {
      margin: 30px 0;
    }
    .table table {
      width:100%;
    }
  }
}