.cards {
  position:relative;
  margin-left:-19px;
  margin-top:-8px;
  width:calc(100% + 19px) !important;
  margin-bottom:13px;

  @include phone {
    margin-left:0;
    margin-top:0;
    width:100% !important;
    margin-bottom:0;
  }

  .card {
    cursor:pointer;
    position:relative;
    box-shadow: 0 0 8px 2px rgba(79, 110, 149, 0.3);
    border: 2px solid transparent;
    border-radius: 18px;
    width: calc(33.3333% - 19px);
    margin-left: 19px;
    margin-top:18px;
    height:160px;
    display: inline-flex;
    text-decoration:none;

    @include tablet {
      width: calc(50% - 19px);
    }

    @include phone {
      width:100%;
      margin-left:0;
      margin-top:0;
      height:150px;
      & + .card {
        margin-top:10px;
      }
    }

    .icon {
      width: 35.6%;
      left:0;
      padding-left: 8px;
      display:flex;
      justify-content:center;
      padding-bottom: 27px;

      @include phone {
        padding-left:2px;
        padding-bottom: 40px;
      }

      img {
        &[type=quality] {
          width: 63.33%;
          max-width:70.1px;
        }
        &[type=claims] {
          width: 62.64%;
          max-width:69.24px;
        }
        &[type=enote] {
          width: 61.7%;
          max-width:68.2px;
        }
        &[type=debt] {
          width: 59.56%;
          max-width:65.83px;
        }
        &[type=boomerang] {
          width: 62.55%;
          max-width:69.15px;
        }
        &[type=promotions] {
          width: 63.51%;
          max-width:70.2px;
        }
        &[type=price] {
          width: 45.15%;
          max-width:49.9px;
        }
        &[type=support] {
          width: 51.07%;
          max-width:56.45px;
        }
        &[type=managers] {
          width: 63.65%;
          max-width:70.36px;
        }
      }
    }
    .text {
      padding-top:31px;
      width:64.4%;
      padding-right: 7%;

      @include phone {
        padding-top:24px;
      }

      strong {
        font-size:18px;
        line-height: 111%;
        & + * {
          margin-top:10px;
        }
      }
      p {
        font-size: 12px;
        line-height: 127%;
        & + p {
          margin-top:10px;
        }
        
        &.underline {
          text-decoration: underline;
        }
      }
      &.price {
        p + p {
          margin-top:15px;
        }
      }
      
      ul {
        font-size: 12px;
        line-height: 18px;
        li {
          position:relative;
          &:before {
            content: '\2013';
            position:relative;
            margin-right:2px;
          }
        }
      }
    }
    @include hover {
      border-color: #AFE074;
    }
  }
}