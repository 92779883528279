.breadcrumbs {
  font-size:12px;
  color:$text_grey;
  line-height: 16px;
  a {
    color:$text_grey;
  }
  @include phone {
    display:none;
  }
}
.rounded {
  overflow:hidden;
  border-radius: 18px;
}
.free {
  width:auto;
}
.filter {
  box-shadow: 0 0 8px rgba(79, 110, 149, 0.1);
  border-radius:18px;
  background:white;
  padding:28px 30px 27px;

  &[padding=low] {
    padding-top:13px;
  }

  & + .table {
    margin-top:21px;
    @include phone {
      margin-top:19px;
    }
  }

  &-left {
    width: calc(100% - 184px);
    @include phone {
      flex-direction:column !important;
      margin-top: 19px;
      width:100% !important;
    }
    .inline {
      width: auto;
      @include phone {
        width:100% !important;
      }
      & + .inline {
        margin-left: 49px;
        @include desktop-lower {
          margin-left:18px;
        }
        @include phone {
          margin-left:0 !important;
          margin-top:22px !important;
        }
      }
    }
    .column {
      @include phone {
        margin-left: 0 !important;
        width: 100% !important;
      }
      & + .column {
        margin-left: 49px;
        @include desktop-lower {
          margin-left:18px;
        }

        @include phone {

        }
      }
    }
  }

  .right {
    width: 184px;
    padding-left: 36px;
  }
  .button[type=download] {
    @include phone {
      display:none;
    }
  }
  .column {
    & > * + * {
      margin-top:25px;
      @include phone {
        margin-top:31px;
      }
    }
    & > .form-item + .form-item {
      margin-top:8px;
      @include phone {
        margin-top:14px;
      }
    }
  }
}

.products-notice {
  margin-bottom:15px;
  color:$text_dark;
  padding:0 30px;
  font-size:16px;
  @include phone {
    padding: 0 15px;
    font-size:14px;
  }
}

.table {
  @include phone {
    margin-left:-10px;
    width: calc(100% + 20px);
    .no-items, .loading {
      margin-left:10px;
      margin-bottom: 10px;
      display: block;
    }
  }

  &-container {
    position:relative;
  }

  &-wrapper {
    width:100%;
    overflow-x:auto;
  }
  table {
    background:white;
    width:100%;
    border-radius: 18px 18px 0 0;
    // overflow:hidden;
    @include phone {
      border-radius:0;
      width:1000px;
    }
    tr {
      td {
        vertical-align: middle;
        line-height: 16px;
        padding:12px 10px;
        @include phone {
          padding:12px 20px;
        }

        .checkbox {
          label {
            line-height: 17px;
            color:$text_dark;
            padding-left:44px;
          }
        }

        &:first-of-type {
          padding-left:30px;
          @include phone {
            padding-left:15px;
          }
        }
        &:last-of-type {
          padding-right:30px;
          @include phone {
            padding-right:15px;
          }
        }
        &[type=productCode] {
          width:12.95%;
        }
        &[type=productName] {
          width: 39%;
        }
        &[type=manufacturer] {
          width: 14.1%;
        }
        &[type=mechanics] {
          width: 22%;
        }
        &[type=multiplicity] {
          width: 13.4%;
        }

        &[type=claimNumber] {
          width: 14.1%;
        }
        &[type=typeName] {
          width:15%;
        }
        &[type=claimDate] {
          width:8.4%;
        }

        &[type=invoiceNumber] {
          padding-left: 15.3%;
          width: 28.8%;
          @include desktop-lower {
            padding-left: 12.3%;
          }
          @include tablet-lower {
            padding-left: 5.3%;
            width: 17.8%;
          }
        }
        &[type=statusName] {
          width: 20.5%;
        }
        &[type=verdictName] {

        }

        &.center {
          text-align:center;
        }
      }
      &.table-header {
        td {
          span.offset {
            padding-left:44px;
          }
          &:first-of-type {
            border-radius:18px 0 0 0;
            @include phone {
              border-radius:0;
            }
          }
          &:last-of-type {
            border-radius:0 18px 0 0;
            @include phone {
              border-radius:0;
            }
          }
          white-space:nowrap;
          user-select:none;
          background:$super-light;
          @include phone {
            white-space: nowrap;
          }
          [sort] {
            cursor:pointer;
            position:relative;
            display:inline-block;
            padding-right:16px;
            &:before, &:after {
              right:0;
              height:6.82px;
              width:5.33px;
            }
            @include before {
              background:url(~@/assets/images/ar-triangle-gray.svg);
              background-size:100%;
              transform:translate(0, -50%) rotate(90deg);
              margin-top: -2px;
            }
            @include after {
              background:url(~@/assets/images/ar-triangle-gray.svg);
              background-size:100%;
              transform:translate(0, -50%) rotate(-90deg);
              margin-top: 4px;
            }
          }
          .sort[sort=DESC] {
            cursor:pointer;
            &:after {
              background:url(~@/assets/images/ar-triangle-green.svg);
              background-size:100%;
            }
          }
          .sort[sort=ASC] {
            cursor:pointer;
            &:before {
              background:url(~@/assets/images/ar-triangle-green.svg);
              background-size:100%;
            }
          }
        }
      }
    }
  }
  &-control {
    background:$super-light;
    border-radius:0 0 18px 18px;
    user-select:none;
    @include phone {
      border-radius:0;
      padding:0 20px;
    }
  }
  &-paginator {
    padding:16px 0 14px;
    &-arrow {
      width:30px;
      height:30px;
      display:flex;
      align-items:center;
      justify-content:center;
      cursor:pointer;
      svg {
        position:absolute;
        width:8px;
        path {
          transition:0.25s;
        }
      }
      &._right {
        svg {
          transform:rotate(180deg);
        }
        margin-left:15px;
      }
      &._left {
        margin-right:19px;
      }
      @include hover {
        svg path {
          fill:$green;
        }
      }
      &:active {
        svg path {
          transition:0s;
          fill: $dark_green;
        }
      }
    }

    .current {
      & + span {
        margin-left:9px;
      }
    }
    @include phone {
      padding:16px 0;
      margin:0;
      justify-content: space-between;
      border-bottom:1px solid rgba($light_grey, 0.5);
    }
  }
  .per-page {
    margin-left: 4.3%;
    @include phone {
      justify-content: space-between;
      margin:0;
      padding:15px 0;
    }
    .select {
      margin-left:11px;
    }
  }
}
.close {
  cursor:pointer;
  position: absolute;
  right: -10px;
  top: -20px;
  width:16.56px;
  height:16.56px;

  @include phone {
    top:0;
    right:0;
  }

  &:before, &:after {
    background: $light_grey;
    border-radius: 2px;
    width:23px;
    height:1px;
    left:50%;
    transition:0.25s;
  }
  @include before {
    transform:translate(-50%,-50%) rotate(45deg);
  }
  @include after {
    transform:translate(-50%,-50%) rotate(-45deg);
  }
  @include hover {
    &:before, &:after {
      background: $icon_grey;
    }
  }
  &:active {
    &:before, &:after {
      transition:0s;
      background: $text_dark;
    }
  }
}

.loading {
  margin:0;
  padding-left:28px;
  position:relative;
  &:before {
    content:'';
    position:absolute;
    top:50%;
    transform:translate(0,-50%);
    left:10px;
    height:20px;
    width:20px;
    background:url(~@/assets/images/loader.svg);
    background-size:100%;
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}
.no-items {
  margin:0;
}
.disabled {
  opacity:0.5;
  pointer-events:none;
}
.hide {
  opacity:0;
  pointer-events:none;
}

.padded {
  line-height:25px;
  font-size: 14px;
  padding:7.5px 20px;
  border-radius: 18px;
  cursor:pointer;

  @include hover {
    background:rgba($green, 0.1);
  }
  &.active {
    background:rgba($green, 0.3);
    pointer-events:none;
  }

  @include phone {
    margin-left:-10px;
    width:calc(100% + 20px);
    padding:7.5px 10px;
  }
}

.switcher-group {
  position:relative;
  display:none;

  &:after {
    content:'';
    position:absolute;
    background:white;
    width:calc(100% - 18px);
    height:10px;
    bottom:-10px;
    left:0;
    z-index:2;
  }

  @include phone {
    display:block;
    &.column {
      display:flex;
    }
    &:after {
      display:none;
    }
  }
  .button {
    @include phone {
      margin-right: 8px;
      margin-top: 8px;
    }
  }
  &.column {
    .button + .button {
      margin-left:0;
      margin-top:20px;
    }
  }
}

.separator {
  height:1px;
  width:100%;
  background:$light_grey;
  opacity:0.5;
  margin:23px 0 19px;
}

.vdp-datepicker__calendar {
  box-shadow: 0 0 8px 3px rgba(79, 110, 149, 0.1);
  border-radius: 18px;
  border:0 !important;
  padding: 20px 16px 20px 9px;
  width:250px !important;
  .prev {
    background: none !important;
    &:after {
      border-right: 10px solid $light_grey !important;
      transition:0.25s;
    }
    &:hover:after {
      border-right: 10px solid $green !important;
    }
    &:active:after {
      border-right: 10px solid $dark_green !important;
    }
  }
  .next {
    background: none !important;
    &:after {
      border-left: 10px solid $light_grey !important;
      transition:0.25s;
    }
    &:hover:after {
      border-left: 10px solid $green !important;
    }
    &:active:after {
      border-left: 10px solid $dark_green !important;
    }
  }
  header {
    line-height: 26px !important;
    .up {
      background:none !important;
      @include hover {
        color:$green;
      }
      @include active {
        color:$dark_green;
      }
    }
  }
  .cell {
    border: 0 !important;
    padding: 0 !important;
    &.selected {
      background: $super-light !important;
      pointer-events:none;
    }

    &:not(.blank) {
      @include hover {
        background: $green;
        color: white !important;
      }
      @include active {
        background: $dark_green;
        color: white !important;
      }
    }
    &.day {
      border-radius: 50%;
      width: 24px !important;
      height: 24px !important;
      line-height: 24px !important;
      margin-left: 7px;
      margin-top: 5px;
      &.weekend {
        color:$error;
      }
    }
    &.day-header {
      color:$text_grey;
      width: 24px !important;
      height: 24px !important;
      line-height: 24px !important;
      margin-left: 7px;
      margin-top: 5px;
    }

    &.month, &.year {
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
    }
  }
}
.error {
  color:$error;
  display:none;
  text-align:right;
  width:100%;
  @include phone {
    text-align:left;
  }
  &._show {
    display:block;
  }
}