.footer {
  color:white;
  background:$light_grey;
  padding:30px 0 50px;
  @include phone {
    padding:18px 0 16px;
    background:#F5F7F9;
    color:$light_grey;
  }
  a {
    color:white;
  }

  .grid {
    padding-right:20px;
    & > * {
      line-height: 16px;
      & + a,
      & + span {
        margin-top:12px;
      }
      & + strong {
        margin-top:25px;
      }
    }
    @include phone {
      display:none;
    }
    &:last-of-type {
      padding-right:0;
      @include phone {
        display:flex;
        width:100%;
      }
      span {
        font-size:12px;
        & + span {
          margin-top:9px;
          @include phone {
            margin-top:4px;
          }
        }
      }
    }
  }
  .social {
    margin-top: 20px;
    &-item {
      display:block;
      width:35px;
      transition:0.3s;
      filter: invert(94%) sepia(12%) saturate(93%) hue-rotate(177deg) brightness(96%) contrast(96%);
      @include hover {
        //filter: invert(87%) sepia(1%) saturate(3616%) hue-rotate(198deg) brightness(115%) contrast(94%);
        filter: invert(100%) sepia(100%) saturate(17%) hue-rotate(297deg) brightness(105%) contrast(107%);
      }
      img {
        width:100%;
      }
      & + .social-item {
        margin-left:20px;
      }
    }
    @include phone {
      display:none;
    }
  }
}