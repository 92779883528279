.blackout {
  position:fixed;
  z-index:1000;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  height: calc(var(--vh, 1vh) * 100);

  background:rgba($text_dark, 0.6);
}
.popup {
  background:white;
  box-shadow: 0 0 8px 3px rgba(79, 110, 149, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 18px;
  padding:40px 30px;

  .mobule-bottom {
    padding-bottom:2px;
  }

  &[size=big] {
    width:1049px
  }
  &[size=medium] {
    width:706px
  }
  &[size=small] {
    width:693px
  }
  &[size=mini] {
    width:337px;
    .popup-inner {
      & > * + * {
        margin-top:30px;
      }
    }
    .mobile-bottom {
      margin-top:30px;
    }
  }

  .table {
    margin-top:26px;
    @include phone {
      margin-left: -20px;
      width: calc(100% + 40px);
    }
    table tr td:first-of-type {
      padding-left:20px;
    }
    table tr td:last-of-type {
      padding-right:20px;
    }
  }
  &-header {
    width:100%;
    position:relative;
    h1 {
      margin:0;
      @include phone {
        padding-right:25px;
      }
      span {
        font-weight:normal
      }
    }
    & + .loading {
      display: block;
      @include phone {
        margin-top:30px !important;
      }
    }
    &.inline {
      h1 {
        padding-right:0;
      }
    }
    .wrap {
      .button {

      }
    }
  }
  &-content {
    margin-top:14px;
    margin-left:-30px;
    width:calc(100% + 60px) !important;
    padding:1px 30px;
    p {
      line-height: 25px;
    }
  }

  .scroll-content {
    height:auto;
    max-height: 80vh;
  }

  &_SelectPoint {
    @include phone {
      padding-bottom:54px;
    }
  }
}


@media screen and (max-width:1070px) {
  .popup[size=big] {
    width: calc(100% - 40px);
  }
}