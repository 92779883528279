.boomerang {
  .filter {
    .button {
      & + .button {
        margin-left:32px;
        @include tablet {
          margin-left:0;
        }
      }
    }
    .checkbox {
      label {
        white-space: nowrap;
      }
    }
    .range {
      margin-right:32px;
      @include tablet {
        margin-right:0;
      }
    }
    & > .inline {
      @include tablet-lower {
        flex-direction:column;
      }

      & > .inline {
        @include tablet {
          flex-direction:column;
          align-items: flex-start;
          & > * + * {
            margin-top:21px;
            @include phone {
              margin-top:28px;
            }
          }
        }

        &.filter-left {
          width:auto;
        }

        &:last-of-type {
          align-items: flex-end;
          @include tablet-lower {
            justify-content: flex-start;
            margin-top:21px;
            flex-direction:row;
            .button + .button {
              margin-top:0;
              margin-left:20px;
            }
          }
        }
      }
    }
    & + .inline {
      margin-top:28px;
      .summary {
        justify-content: space-around;
        width:30.13%;
        margin-right:3.8%;
        padding-left:30px;
        @include desktop-lower {
          width:calc(40% - 40px);
          margin-right:40px;
          justify-content: flex-start;
          padding-left:0;
        }

        @include phone {
          width:100%;
          margin-right:0;
        }

        p {
          color:$text_grey;
        }
        strong {
          font-size: 24px;
          @include desktop-lower {
            margin-top:10px;
          }
          @include phone {
            margin-top:0;
          }
        }
        .inline {
          @include desktop-lower {
            flex-direction:column;
            align-items: flex-start;
            margin-top:25px;
          }
          @include phone {
            flex-direction:row;
            margin-top:15px;
          }
        }
      }
      .report {
        width:66.07%;
        box-shadow: 0 0 8px 3px rgba(79, 110, 149, 0.1);
        border-radius: 18px;
        padding:17px 30px 29px;

        @include desktop-lower {
          width:60%;
        }
        @include phone {
          display:none;
          &._show {
            display:block;
          }
        }

        .form-item {
          width:calc(50% - 99px);
          .select {
            width:100%;
          }
          & +.form-item {
            margin:0 30px 0 20px;
            @include desktop-lower {
              margin:8px 0;
            }
          }
          @include desktop-lower {
            width:100%;
          }
        }
        .mobile-bottom {
          margin-top:17px;
          @include desktop-lower {
            width:100%;
            .button {
              width:100%;
            }
          }
        }
        .inline {
          margin-top:9px;
          &:first-of-type {
            margin-top:0;
            flex-direction:row;
          }
          @include desktop-lower {
            flex-direction:column
          }
        }
      }
    }
  }
  .table {
    margin-top:27px;
    td {
      &[type=name] {
        width:33.1%
      }
      &[type=manufacturer] {
        width:13.5%
      }
      &[type=count] {
        width:6.7%
      }

      &[type=sumWithVat] {
        width: 11%;
      }
      &[type=invoiceNumber] {
        padding-left: 10px;
        width:auto;
      }

    }
  }
}