.manager {
  padding:35px 0 23px;
  @include phone {
    padding:29.5px 0 22.5px;
  }

  &:first-of-type {
    padding-top:20px;
    @include phone {
      padding-top:10px;
    }
  }

  &-photo {
    overflow:hidden;
    width:120px;

    .real-photo {
      background: #C4C4C4;
      border-radius: 40px;
    }
    @include phone {
      width:43%;
    }
    img {
      width:100%;
    }
  }

  &-info {
    padding-left:28px;
    width:calc(100% - 120px);

    @include phone {
      padding-left:0;
      padding-top:22px;
      width:100%;
    }

    h4 {
      font-size: 18px;
      line-height: 25px;
    }
    & > div {
      margin-top:20px;
      line-height: 25px;

      @include phone {
        &:first-of-type {
          margin-top: 15px;
        }
        &:last-of-type {
          margin-top: 8px;
        }
      }

      .mob-phone {
        @include phone {
          position:relative;
          @include before {
            background:url(~@/assets/images/mobile-phone.svg);
            background-size:100%;
            width:35px;
            height:35px;
            right:0;
          }
        }
      }

      .inline {
        & + .inline {
          margin-top:15px;
        }
      }

      span {
        font-size:16px;
        font-weight:bold;
        @include phone {
          margin-top:-5px;
        }
      }
      p {
        color:$text_grey;
        width:159px;
      }
    }
  }
  & + .manager {
    border-top:1px solid rgba($light_grey, 0.5);
  }
}