.header {
  padding:29px 0 19px;

  @include phone {
    padding:7px 0 10px;
    background:#F0F4F7;
    .wrapper > .inline {
      align-items: center;
      & + .inline {
        margin-top:7px;
        position:relative;
        padding-top:11px;
        border-top:1px solid $super-light;
      }
    }
  }

  .phone-buttons {
    display:none;
    .phone-button {
      &:first-child {
        width:calc(50% + 3px);
      }
      &:last-child {
        width:calc(50% - 17px);
      }
    }
    @include phone {
      display:inline-flex;
    }
  }

  .logo {
    display:block;
    width:160px;
    // margin-right:62px;
    margin-right:4.4%;
    margin-top: -4px;
    img {
      width:100%;
    }
    & + div {
      // width:calc(100% - 222px);
      width: calc(95.6% - 160px);
      position:relative;
      & > .inline + .inline {
        margin-top:8px;
        @include phone {
          display:none;
        }
      }
      @include phone {
        width:calc(100% - 100px);
      }
    }
    @include phone {
      width:37px;
      margin-left:16px;
      margin-top:0;
      margin-right:21px;
    }
  }

  .network {
    width: calc(100% - 35px);
    @include phone {
      width:100%;
    }
    &-address {
      font-size:16px;
      line-height: 16px;
      max-width: calc(100% - 114px);
      overflow:hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      color:$text_dark;
      @include phone {
        max-width:100%;
        white-space: normal;
        font-size: 10px;
        max-height:32px;
      }
      & + .button {
        margin-left:16px;
        @include phone {
          display:none;
        }
      }
    }
  }

  .managers {
    width: calc(12.5% + 179px);
    position:relative;
    padding-left:42px;
    white-space:nowrap;
    color:$text_grey;
    cursor:pointer;
    user-select:none;

    @include phone {
      display:none;
    }
    svg {
      position:absolute;
      width:32.5px;
      top:50%;
      transform:translate(0,-50%);
      margin-top: -2px;
      left:0;
      path {
        transition:0.25s;
        fill:$icon_grey;
      }
    }
    @include hover {
      text-decoration-line: underline;
      svg path {
        transition:0s;
        fill:$text_grey;
      }
    }
    &:active {
      color:$text_dark;
      svg path {
        fill:$text_dark;
      }
    }
  }

  .header-icon {
    position:relative;
    padding-left:29px;
    text-decoration:none;
    white-space:nowrap;
    @include before {
      background:#E5E9EF;
      left:0;
      width:22px;
      height:22px;
      border-radius:50%;
      transition:0.25s;
    }

    &.phone {
      font-weight:bold;
      svg {
        width:16px;
        left:3px;
        position:absolute;
        top:50%;
        transform:translate(0,-50%);
        path {
          transition:0.25s;
        }
      }
      @include hover {
        text-decoration:underline;
        svg path {
          fill:$text_grey;
        }
        &:before {
          background:rgba($light_grey, 0.6)
        }
      }
      &:active {
        svg path {
          transition:0s;
          fill:$text_dark;
        }
        &:before {
          transition:0s;
          background:$light_grey
        }
      }
    }
    &.user {
      @include after {
        position:absolute;
        width:16px;
        height:16px;
        left:3px;
        background:url(~@/assets/images/user.svg);
        background-size:100%;
      }
    }
    & + .header-icon {
      margin-left:25px;
    }
  }

  .logout {
    cursor:pointer;
    position: absolute;
    top: -12px;
    right:-13px;
    width:48px;
    height:50px;
    display:flex;
    align-items:center;
    justify-content: center;
    svg {
      pointer-events:none;
      width:24px;
      path {
        transition:0.25s;
        fill:$light_grey;
      }
    }
    @include hover {
      svg path {
        fill:$icon_grey;
      }
    }
    &:active {
      svg path {
        transition:0s;
        fill:$text_dark;
      }
    }
    @include phone {
      display:none;
    }
  }
}
.burger {
  margin-left:10px;
  width:26px;
  height:21px;
  flex-direction:column;
  justify-content:space-between;
  display:none;
  @include phone {
    display:flex;
  }
  & > div {
    width:100%;
    height:3px;
    background:#7789A0;
    border-radius: 3px;
  }
}
.header-link {
  margin-left: 22px;

  & a {
    text-decoration: none;
    margin-left: 8px;
    color: #69758C;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  & img {
    width: 13px;
    height: 13px;
  }
}