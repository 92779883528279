.popup.message-form {
  .inline {
    margin-top: 13px;
    &.mobile-bottom {
      margin-top:30px;
    }
    .column {
      width: calc(50% - 15px);
      @include phone {
        width: 100%;
        & + .column {
          margin-top: 13px;
        }
      }
      .form-item {
        &:not([size=small]) {
          width: 100%;
        }
        &[size=small] {
          width:160px;
        }
        & + .form-item {
          margin-top: 13px;
        }
      }
      &._short {
        @include phone {
          margin-top: 13px;
          .form-item + .form-item {
            margin-bottom:0;
          }
        }
      }
    }
  }

  p {
    & + .inline {
      margin-top: 13px;
      @include phone {
        margin-top: 16px;
      }
    }
  }
  .popup-content {
    @include phone {
      padding-bottom: 64px;
    }
  }
}
