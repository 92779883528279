.login {
  height:100%;
  background:url(~@/assets/images/login-bg.jpg) center center no-repeat;
  background-size:cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @include phone {
    background:none;
  }

  label {
    font-size:14px;
  }

  &-popup {
    display:block !important;
    width:337px;
    position:relative;
    background:white;
    padding:37px 30px 40px;
    border-radius: 18px;
    .logo {
      width:160px;
    }
    h1 {
      margin: 49px 0 27px;
      @include phone {
        font-size:24px;
        margin-top:39px;
      }
    }
    .checkbox label {
      padding-left:33px;
    }

    .error {
      text-align:left;
      margin-top: 25px;
      color: $error;
      line-height: 16px;
    }

    .form-item {
      & + .form-item {
        margin-top:23px;
      }
    }

    .login-controls {
      margin-top:34px;
      @include phone {
        margin-top:22px;
      }
    }
  }
}