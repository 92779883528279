@mixin desktop {
  @media (min-width:769px) {
    @content;
  }
}


@mixin desktop-low {
  @media (max-width:1919px) {
    @content;
  }
}
@mixin tablet-lower {
  @media (max-width:900px) {
    @content;
  }
}

@mixin desktop-lower {
  @media (max-width:1320px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width:1440px) {
    @content;
  }
}

@mixin phone {
  @media (max-width:768px) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    transition:0.25s;
    &:hover {
      @content;
    }
  }
}
@mixin active {
  @media (hover: hover) {
    &:active {
      transition: 0s;
      @content;
    }
  }
}

@mixin before {
  &:before {
    content:'';
    position:absolute;
    top:50%;
    transform:translate(0,-50%);
    @content;
  }
}
@mixin after {
  &:after {
    content:'';
    position:absolute;
    top:50%;
    transform:translate(0,-50%);
    @content;
  }
}