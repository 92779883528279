.popup[type=Invoice] {
  .popup-content {
    margin-top:18px;
  }
  .form-item {
    width: calc(50% - 24px);
    @include tablet-lower {
      width: 45%;
    }
    @include phone {
      width:100%;
    }
    & + .form-item {
      margin-left:24px;

      @include phone {
        margin-left:0;
        margin-top:8px;
      }
    }
  }

  .top-line {
    .button {
      margin-top:17px;
      @include phone {
        margin-top:10px;
      }
      & + .button {
        margin-left:24px;
        @include phone {
          margin-left:0;
        }
      }
    }
  }

  .button[type=confirm] {
    @include phone {
      margin-top:25px;
    }
  }

  .button[type=download] {
    @include phone {
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-bottom: 0;
    }
    & + .button[type=download] {
      margin-left:24px;
      @include phone {
        margin-left:-10px;
      }
    }
  }

  .table {
    margin-top:20px;
    @include phone {
      margin-top:25px;
    }
    td[type=manufacturer] {
      width:20%;
    }
    &-wrapper {
      overflow:visible;
      @include phone {
        overflow-x: auto;
      }
    }
  }
}