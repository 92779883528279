.popup[type=SendToManager] {
  .to-manager {
    padding:8px 0 6px 26px;
    line-height:16px;
    strong {
      display:block;
      margin-bottom:7px;
      position:relative;
      @include before {
        left:-26px;
        background:url(~@/assets/images/user.svg);
        background-size:100%;
        width:16px;
        height:16px;
      }
    }
    a {
      color:$green;
    }
  }
}