.popup[type=Claim] {
  @include phone {
    padding-bottom:10px !important;

    .button[type=download] {
      @include phone {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-bottom: 0;
      }
    }
  }
  .mobile-bottom {
    padding-bottom:10px !important;
  }

  .column {
    width: 278px;
    div + div {
      margin-top:14px;
    }
    @include phone {
      width:100%;
    }
  }

  .wrap {
    width:calc(100% - 278px);
    .inline {
      width:auto;
    }
    @include phone {
      margin-top:19px;
      width:100%;
    }
  }
  .popup-header {
    .wrap {
      margin-left:-24px;
      margin-top:-10px;
      *:not(.inline):not(svg) {
        margin-left:24px;
        margin-top:10px;
      }

    }
    @include phone {
      .wrap {
        margin:19px 0 0;
        *:not(.inline):not(svg) {
          margin:0;
        }
      }
      flex-direction:column;
      .button[type=download] {
        display: none;
      }
      h1 {
        font-size:24px;
      }
      .button {
        margin-top:0;
        margin-left:0;
        width:100% !important;
        line-height: 36px;
      }
    }
  }

  .popup-content {
    margin-top:29px;
    @include phone {
      margin-top:23px;
      .wrap {
        margin-top:23px;
      }
    }
  }

  .table {
    table tr td {
      &[type=productCode] {
        width: 12.6%;
      }
      &[type=productName] {
        width: 33.8%;
      }
      &[type=manufacturer] {
        width: 17.9%;
      }
      &[type=seriesNumber] {width: 9.4%;}
      &[type=quantity] {
        width: 8.4%;
      }
      &[type=price] {
        width: 7%;
      }
      &[type=sumWithVat] {}
    }
  }
}
.claim-text {
  span {
    font-size:14px;
    white-space:nowrap;
  }
  strong {
    font-size:16px;
  }
  div + strong {
    margin-top:12px;
    display:block;
    line-height:20px;
  }
}

.claim-attachments {
  display: flex;
  .attachments {
    margin: 0 0 0 9px !important;
    width: 200px;
    color:$green;
    font-size: 14px;
    line-height: 16px;

    span {
      word-wrap: break-word;
      white-space: normal;
    }

    .attach-more {
      text-align: center;
    }
  }

  @include phone {
    flex-direction: column;
    width: 100%;

    .attachments {
      margin-left: 0 !important;
      margin-top: 9px !important;
      width: 100%;
    }

  }
}

.claim-grid {
  width:calc(50% - 12px);
  @include phone {
    width:100%;
  }
  & + .claim-grid {
    margin-left:24px;
    @include phone {
      margin-left:0;
      margin-top:23px;
    }
  }
}
