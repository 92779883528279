.form-item {
  display:flex;
  flex-direction:column;
  padding-top:17px;
  position:relative;

  @include phone {
    width:100%;
  }

  &[size=small] {
    width:130px;

    & + [size=small] {
      margin-left:18px;
    }

    @include phone {
      width:calc(50% - 8px);
    }
  }
  &[size=medium] {
    width:148px;
  }

  &[size=big] {
    width:308px;
  }

  &[size=full] {
    width:100%;
  }

  .text-input, textarea {
    &:focus, &:valid, &[disabled] {
      background:$active_field;
      & + label {
        font-size: 10px;
        line-height: 16px;
        top:0;
      }
    }
    &._big {
      & + label {
        line-height: 40px;
        font-size: 16px;
        padding: 0 15px;
      }
      &:focus, &:valid, &[disabled] {
        & + label {
          font-size: 14px;
          line-height: 16px;
          top:-7px;
        }
      }
    }
  }

  label {
    white-space:nowrap;
    pointer-events:none;
    position:absolute;
    font-size: 14px;
    line-height: 30px;
    color:$text_grey;
    padding:0 0 1px 15px;
    transition:0.25s;
    top:17px;
    span {
      &:first-of-type {
        display: inline;
      }
      &:last-of-type {
        display: none;
        color:$error;
      }
    }
    & + .text-input {
      &._big {
        margin-top: 7px;
      }
    }
  }
  &._error {
    label {
      color:$error;
      span {
        &:first-of-type {
          display:none;
        }
        &:last-of-type {
          display:inline;
        }
      }
    }
    .text-input {
      border-color:$error;
    }
  }
  & + .sub {
    color:$green;
    font-size: 10px;
    line-height: 16px;
    margin-left:15px;
    margin-top:9px;
  }
}
textarea {
  border: 1px solid $light_grey;
  border-radius: 8px;
  color:$text_dark;
  font-size: 14px;
  font-family: 'PT Sans', sans-serif;
  transition:0.25s;
  resize:none;
  width:100%;
  height:85px;
  padding:7px 17px;
  &:focus {
    background:$active_field;
  }
  &._error {
    border-color:$error;
    & + label {
      color:$error;
    }
  }
}
.text-input,
.vdp-datepicker input {
  cursor: pointer!important;
  line-height:28px;
  border: 1px solid $light_grey;
  border-radius: 70px;
  width:100%;
  padding:0 17px;
  color:$text_dark;
  font-size: 14px;
  font-family: 'PT Sans', sans-serif;
  transition:0.25s;
  &._second {
    text-align:center;
    width:46px;
    border:none;
    padding:0 12px;
    background:$active_field;
    line-height:30px;
  }
  &._big {
    line-height:38px;
    font-size:16px;
    padding:0 15px;
  }
  &:focus {
    background:$active_field;
  }
  &[disabled] {
    cursor:not-allowed
  }
}

::placeholder {
  color: $text_grey;
}


.counted-list {
  margin-top:23px;
  margin-left:-28px;
  width:calc(100% + 28px);
  @include phone {
    margin-left:-10px;
    width:calc(100% + 20px);
  }
}

.checkbox {
  & > input {
    display:none;
    &:checked {
      & + label {
        color:$text_dark;
        &:after {
          transform: scale(1)
        }
      }
    }
  }

  &.round {
    label:before {
      border-radius:50%;
    }
  }

  label {
    user-select:none;
    cursor:pointer;
    position:relative;
    padding-left:37px;
    display:block;
    line-height: 16px;
    color:$text_grey;
    transition:0.25s;

    &:before {
      content:'';
      position:absolute;
      left:0;
      top:-4px;
      width:24px;
      height:24px;
      background:white;
      border:1px solid $light_grey;
      box-sizing:border-box;
      border-radius: 9px;
      transition:0.25s;
    }
    &:after {
      content:'';
      position:absolute;
      left:6px;
      top:2px;
      background: $green;
      border-radius: 12px;
      width:12px;
      height:12px;
      transition:0.25s;
      transform:scale(0)
    }
    &.no-hover {
      display:inline;
    }
  }
    @include hover {
      label:not(.no-hover):before {
        background: $active_field;
      }
    }
  &._center {
    label {
      white-space: nowrap;
      line-height:30px;
      &:after {
        top: 50%;
        transform: translate(0, -50%) scale(0);
      }
      &:before {
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    input:checked + label {
      &:after {
        transform: translate(0, -50%) scale(1);
      }
    }
  }
  &.counted {
    margin-left:18px;
    width:calc(33.33% - 18px);
    position:relative;

    @include desktop-lower {
      width:calc(50% - 18px);
    }
    @include phone {
      width:100% !important;
      margin-left:0;
    }

    @include hover {
      input + label:before {
        background:$active_field;
      }
    }
    input:checked + label {
      &:after {
        z-index:2
      }
      &:before {
        z-index:1
      }
      div {
        position:relative;
        &:before {
          display:block;
        }
        &:after {
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(220,236,200,1) 100%);
          right:64px;
        }
      }
      .text-input {
        display:block;
      }
      span {
        width: calc(100% - 64px);
      }
    }

    label {
      padding-left:49px;
      &:before {
        background:white;
        top:8px;
        left:10px;
        @include phone {
          top:10px;
        }
      }
      &:after {
        top:14px;
        left:16px;
        @include phone {
          top:16px;
        }
      }

      & > div {
        @include phone {
          padding: 6px 0;
        }
        @include before {
          left:-49px;
          width:calc(100% + 49px);
          height:100%;
          background:rgba($green, 0.3);
          border-radius: 18px;
          display:none;
        }
        @include after {
          right:0;
          height:100%;
          width:20px;
          background: rgb(255,255,255);
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
          pointer-events:none;
          @include phone {
            height:24px;
            top:initial;
            bottom:0;
          }
        }
      }

      span {
        line-height: 40px;
        position:relative;
        color:$text_dark;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        display: block;
        @include phone {
          overflow:visible;
          white-space:normal;
          line-height:30px;
          display:inline;
          width: 100% !important;
          padding-right: 54px;
        }
      }
      .text-input {
        width:44px;
        position:absolute;
        right:10px;
        bottom:8px;
        line-height:22px;
        border-radius: 9px;
        padding:0 5px;
        text-align:right;
        display:none;
        @include phone {
          bottom:10px;
        }
      }
    }
  }

  &.solo {
    position:relative;
    width:24px;
    height:24px;
    background:white;
    border:1px solid $light_grey;
    box-sizing:border-box;
    border-radius: 9px;
    transition:0.25s;
    cursor:pointer;

    &:after {
      content:'';
      position:absolute;
      left:5px;
      top:5px;
      background: $green;
      border-radius: 12px;
      width:12px;
      height:12px;
      transition:0.25s;
      transform:scale(0)
    }

    @include hover {
      background:$active_field;
    }
    &._active {
      &:after {
        transform:scale(1)
      }
    }
    &.isDefect {
      pointer-events:none;
      border:2px solid $light_grey;
      transition:0s !important;
      &:before {
        content:'';
        position:absolute;
        transition:0s !important;
        background:url(~@/assets/images/add-light.svg);
        background-size:100%;
        transform:rotate(45deg);
        width:12px;
        height:12px;
        left:4px;
        top:4px;
      }
      &:after {
        display:none;
      }
    }
  }
}

.top.select {
  .list {
    border-top:1px solid $light_grey;
    border-bottom:0;
    bottom: 30px;
    top:auto;
    border-radius:15px 15px 0 0;
  }
  &._open {
    .placeholder {
      border-radius:0 0 15px 15px;
    }
  }
}

.select {
  user-select:none;
  position:relative;

  select {
    border: 1px solid $light_grey;
    border-radius: 15px;
    position:relative;
    transition:background 0.25s, border-color 0.25s, border-radius 0s !important;
    padding:0 0 0 18px;
    width:100%;
    height:30px;
    line-height:28px;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
    background:white;
    font-size:14px;
    -webkit-appearance: none;
    -moz-appearance: none;

    background:url(~@/assets/images/select-ar.svg) calc(100% - 14px) calc(50% + 1px) no-repeat;
    background-size:10px 8px;
  }

  &._filled select {
    background-color:$active_field;
  }

  .placeholder {
    cursor:pointer;
    border-radius: 15px;
    position:relative;
    transition:background 0.25s, border-color 0.25s, border-radius 0s !important;
    padding:0 37px 0 18px;
    border: 1px solid $light_grey;
    line-height:28px;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
    background:$active_field;

    @include hover {
      background:$active_field;

      &:after {
        background:url(~@/assets/images/ar-triangle-green.svg);
        background-size:100%;
      }
    }
    @include after {
      transition:0.25s;
      transform: translate(0,-50%) rotate(-90deg);
      margin-top:1px;
      right:14px;
      width:8px;
      height:10px;
      background:url(~@/assets/images/ar-triangle.svg);
      background-size:100%;
    }
    &.no_value {
      color:$text_grey;
      background:white;
    }
  }
  .list {
    z-index: 4;
    width:100%;
    background:white;
    display:none;
    top:30px;
    position:absolute;
    border-radius:0 0 15px 15px;
    overflow:hidden;
    // padding:8px 0 11px;
    border-left:1px solid $light_grey;
    border-right:1px solid $light_grey;
    border-bottom:1px solid $light_grey;
    max-height: none;
    overflow-y: visible;

    &-item {
      white-space:nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor:pointer;
      line-height:30px;
      padding:0 18px;
      transition:0.25s;
      @include hover {
        background:rgba($super-light, 0.5);
      }
      &.active {
        background:$super-light;
      }
    }
    .scroll-content {
      max-height:120px;
    }
  }
  &._open {
    .placeholder {
      border-radius: 15px 15px 0 0;
      background:none;
      &:after {
        transform: translate(0,-50%) rotate(-90deg) scaleX(-1);
      }
    }
    .list {
      display:block;
    }
    &._second {
      .placeholder {
        background:$active_field;
      }
    }
  }

  &._open, &._filled {
    & + label {
      font-size: 10px;
      line-height: 16px;
      top:0;
    }
  }

  &._second {
    select {
      background-color:$active_field;
      border:0;
      line-height:30px;
      width:71px;
    }
    .placeholder {
      background:$active_field;
      border:0;
      line-height:30px;
    }
    .list {
      background:$active_field;
      padding:0;
      border:0 !important;
      &-item {
        @include hover {
          background:rgba($light_grey, 0.2);
        }
        &.active {
          background:rgba($light_grey, 0.5);
        }
      }
    }
  }
  &[size=small] {
    width:127px;
  }
  &[size=normal] {
    width:308px;
    @include phone {
      width:100% !important;
    }
  }
  &[size=full] {
    width:100%;
  }
  &._error {
    .placeholder, .list, select {
      border-color:$error
    }
    & + label {
      color:$error;
    }
  }
  .scrollbar-track-y {
    opacity:1 !important;
  }
}

.range {
  position:relative;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  width:auto;

  @include phone {
    width:100%;
  }

  & > div,
  & > input {
    width:130px;
    @include phone {
      width:calc(50% - 8px);
    }
  }
  & > span {
    position:absolute;
    & + div,
    & + input {
      margin-left:18px;
    }
  }
  &[size=full] {
    width:100%;
    & > div, & > input {
      width:calc(50% - 8px);
    }
  }
  .vdp-datepicker:last-of-type {
    .vdp-datepicker__calendar {
      right:0;
      left:initial;
    }
  }
}
.vdp-datepicker {
  @include after {
    right:16px;
    width:15px;
    height:16.20px;
    background:url(~@/assets/images/date.svg);
    background-size:100%;
    pointer-events: none;
  }
}


.claims-new-attachments {
  width: 130px;
  color:$green;
  font-size: 10px;
  line-height: 16px;
  margin-top:14px !important;

  @include phone {
    width:100%;
    padding-left: 10px;
  }

  .attachment {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    
    @include phone {
      justify-content:inherit;      
    }

    & .attach-name {
      width: 110px;
      word-wrap: break-word;

      @include phone {
        width:auto;
        margin-right: 10px;
      }
    }
    & .btn-remove::before {
      content:url(~@/assets/images/cross.svg);
      position: relative;
      top: 3px;
      cursor: pointer;
    }
  }

  .link-file-upload {
    margin-left:3px;
  
    &::before {
      content:url(~@/assets/images/paper-clip.svg);
      left: -4px;
      top: 3px;
      position: relative;
    }
  }

  @include phone {
    margin-top: 24px !important;
  }

}

