.webinars {
  padding:25px 20px 28px;
  background:white;
  margin:10px 0 20px;
  @include phone {
    margin:10px 0;
  }
  &-header {
    padding-bottom:2px;
    strong {
      font-size: 16px;
      line-height: 18px;
    }
    div {
      line-height:21px;
      border: 2px solid $green;
      width:44px;
      padding:0 10px;
      border-radius: 30px;
    }
  }

  .webinar {
    padding:14px 40px 16px 0;
    text-decoration:none;
    display:block;
    position:relative;
    @include after {
      background:url(~@/assets/images/double-arrows.svg);
      background-size:100%;
      width:15.6px;
      height:13px;
      right:2.5px;
    }

    & > span {
      font-size: 10px;
      line-height: 13px;
      color:$icon_grey;
    }
    p {
      margin-top:6px;
      font-size: 12px;
      line-height: 15px;
    }

    & + .webinar {
      border-top:1px solid $super-light;
    }

    @include hover {
      & > span {
        text-decoration:underline;
        color:$text_grey;
      }
      p {
        text-decoration:underline;
        color:$green;
      }
      &:after {
        background:url(~@/assets/images/double-arrows-dark.svg);
        background-size:100%;
      }
    }
  }
  .link-to-all {
    font-size:12px;
    margin-top:8px;
  }
}