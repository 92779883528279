.grid {
  &[size="1/2"] {
    width:50%;
  }
  &[size="1/4"] {
    width:25%;
  }
  &[size="1/5"] {
    width:20%;
  }
  &[size="3/4"] {
    width:75%;
  }
  &[size="2/5"] {
    width:40%;
  }
  &[size="1/3"] {
    width:33.3333%;
    @include phone {
      width:100%;
    }
  }
}