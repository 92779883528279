.slider {
  user-select:none;
  position:relative;
  margin-top:6px;
  .swiper-slide {
    a, span {
      display:block;
    }
    img {
      width:100%;
    }
  }

  .loading-slide {
    height:100px;
    background:$light_grey;
    position:relative;
  }

  &-pagination {
    display: flex;
    margin-left: -3px;
    width: calc(73.438% - 17px);
    flex-flow: wrap;
    z-index: 10;
    position: absolute;
    bottom: 36px;
    justify-content: center;
    left:50%;
    transform:translate(-50%,0);
    @include phone {
      bottom:23px;
      left:0;
      transform:none;
      width: calc(100% - 17px);
    }

    & > div {
      background:transparent;
      border-radius:50%;
      width:26px;
      height:26px;
      position:relative;
      transition:0.25s;
      cursor:pointer;
      margin-left:23px;
      &:before {
        content:'';
        width:14px;
        height:14px;
        border-radius:50%;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        background:white;
      }
      &.active {
        background:rgba(255, 255, 255, 0.6);
      }
    }
  }
}