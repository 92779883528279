.aside {
  background: rgba(164, 179, 197, 0.2);
  border-radius: 24px;
  padding:0 10px;
  margin-left:21px;
  width:calc(25.6% - 21px);

  .slider {
    margin:20px 0 10px;
    @include phone {
      margin:10px 0;
    }
  }

  @include tablet {
    width:calc(33.33% - 21px);
  }

  @include phone {
    width:calc(100% + 20px);
    margin-left:-10px;
    margin-top:20px;
  }
  &-top {
    @include phone {
      flex-direction:column-reverse;
      display:flex;
      .slider {
        margin-bottom:0;
      }
      & + .slider {
        margin-top:0;
      }
    }
  }
}