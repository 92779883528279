@mixin claim-lower {
  @media (max-width:1220px) {
    @content;
  }
}
@mixin claim-lowest {
  @media (max-width:870px) {
    @content;
  }
}
.claims {
  .switcher-group {
    display:block;
  }

  table tr td[type=invoiceNumber] {
    min-width: 300px;
    @include desktop-lower {
      min-width: 240px;
    }
    @include tablet-lower {
      min-width: 150px;
    }
  }

  .filter {
    position:relative;
    border-radius:0 18px 18px 18px;

    .loading {
      margin-top: 10px;
    }

    &[type=search] {
      .filter-left {
        @include phone {
          padding-bottom:110px;
        }
        @include claim-lower {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          & > .inline:last-of-type {
            margin-top: 21px;
            margin-left: 0;
            .column {
              flex-direction: row;
              & > * + * {
                margin-top: 0;
                margin-left: 18px;
              }
            }
          }
        }
        @include claim-lowest {
          flex-direction: row;

          .select[size=normal] {
            width: 278px;
          }

          & > .inline:first-of-type {
            flex-direction: column;
            .column + .column {
              margin-left: 0;
              margin-top: 14px;
            }
          }
          & > .inline:last-of-type {
            flex-direction: column;
            margin-top: 18px;
            margin-left: 18px;
            .column {
              flex-direction: column;
              & > * + *,
              & + .column {
                margin-left: 0;
                margin-top: 21px;
              }
            }
          }
        }
      }
    }

    &[type=add], &[type=question] {
      .filter-left {
        @include phone {
          padding-bottom: 54px;
          & > .inline:first-of-type > .inline:first-of-type {
            .column {
              &:first-of-type {
                display: inline-flex;
                flex-direction: row;
                .sub {
                  margin-top: 24px;
                  margin-left: 32px;
                }
              }
              &:last-of-type {
                margin-top: 14px;
              }
            }
            
            .column.add-claim-type-block {
              flex-direction: column;
            }
          }
        }

        & > .inline {
          width:100%;

          @include claim-lowest {
            flex-direction: column;
            align-items: flex-start;
          }

          &:first-of-type > .inline:nth-of-type(2) {
            margin-left: 18px;
            width: calc(100% - 505px);
            height: 102px;

            &._big {
              height:140px;
              @include claim-lowest {
                height:85px;
              }
              textarea {
                height:100%;
              }
            }

            @include desktop-lower {
              width: calc(100% - 474px);
            }
            @include claim-lowest {
              width: 456px;
              margin-left: 0;
              margin-top: 4px;
            }
            @include phone {
              margin-top: 14px !important;
              width: 100%;
            }
          }
        }
        .counted-list {
          .checkbox.counted {
            @include claim-lowest {
              width:456px;
            }
          }
        }
      }
      .no-items, .loading {
        margin-top:23px;
        display:block;
      }
    }
  }
}
