.receivables {

  .receivables-content {
    @include tablet-lower {
      flex-direction:column;
    }
  }

  .about {
    width:34.8%;
    padding-right:30px;
    margin-top:25px;
    line-height:16px;

    @include tablet{
      width:calc(100% - 338px);
    }
    @include tablet-lower {
      width:100%;
      padding-right:0;
    }

    p {
      margin-top:4px;
      & + strong {
        display:block;
      }
    }
    div + div {
      margin-top:14px;
    }
  }
  .blocks-wrapper {
    width:65.2%;
    flex-direction: column;

    @include tablet {
      width:338px;
    }

    @include tablet-lower {
      width:100%;
    }

    .block-notes {
      margin-top: 15px;
    }
  
    .blocks {
      margin-top:14px;

      @include tablet {
        width:338px;
        flex-direction:column;
      }
      @include tablet-lower {
        margin-top:20px;
        width:100%;
      }

      .content-block {
        position:relative;
        background:white;
        padding:17.5px 30px 20px;
        box-shadow: 0 0 8px 3px rgba(79, 110, 149, 0.1);
        border-radius: 18px;

        h4 + * {
          margin-top:8px !important;
        }
        .form-item {
          width:100%;
        }

        & + .content-block {
          margin-left:20px;
          @include tablet {
            margin-top:20px;
            margin-left:0;
          }
        }

        .column {
          width:100%;
          position:relative;
          // width: calc(50% - 25px);
          & > * + * {
            margin-top:25px;
          }
          .column {
            justify-content: space-between;
            height: 100%;
          }
        }

        .rounded {
          margin-top:1.5px;
          padding:35px 30px 20px;
          background:rgba(79, 110, 149, 0.1);

        }
        &-bottom {
          width:100%;
          & > .inline {
            margin-top:25px;
          }
        }
      }
    }
  }
}