.filter[type=quality], .filter[type=qualityDocPkgs] {
  @include desktop-lower {
    padding-top:28px;
  }
  @include tablet-lower {
    & > .inline {
      flex-direction: column;
    }
    .right {
      width:100%;
      padding-left:0;
      display:inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      .button + .button {
        margin-left:18px;
        margin-top:25px;
      }
    }
  }
  @include phone {
    .right {
      flex-direction: column;
    }
  }


  .filter-left {
    @include phone {
      padding-bottom:110px;
    }
    .inline {
      width:100%;
      & + .inline {
        margin-left:0;
        @include phone {
          margin-top:30px !important;
        }
      }
      & > .inline {
        width:calc(100% - 177px);
        margin-left:29px;

        @include desktop-lower {
          margin-top:13px;
          margin-left:0;
          width:100%;
        }
        @include tablet-lower {
          width:278px;
        }

        & > * {
          width:calc(50% - 9px);
          @include phone {
            width:100%;
            &.form-item + .form-item {
              margin-top:13px;
            }
          }
        }
      }
    }
    & > .inline {
      @include desktop-lower {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.filter[type=qualityDocPkgs] {
  .btn-wrapper {
    margin-top:20px;
    border-top: 1px solid rgba(164, 179, 197, 0.5); 
    padding-top: 20px;
  }
}

.popup-switcher {
  &.active {
    @include phone {
      background:rgba(138,193,72, 0.3) !important;
    }
  }
}

.btn-mobile-wrapper {
  margin-top: 22px;
  justify-content: space-between;

  .button[type=download] {
    display: inline-block;
    font-size: 16px;
    min-width: 178px;
    margin: 0;
    line-height: 34px;
    padding: 0 17px 0 35px;
    background: #F7F8FA;
    width: auto;

    svg {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 11.6px;
      left: 15px;
    }
  }
  
  & + .table {
    margin-top:21px;
    @include phone {
      margin-top:19px;
    }
  }
}

.quality {
  .switcher-group {
    display:block;
  }
  
  table {
    tr td {
      &[type=invoiceNumber] {
        width:auto;
        padding-left:30px;
        @include phone {
          padding-left:15px;
        }
      }
    }
    @include phone {
      width:100%;
    }

    .col-checkbox {
      display: flex;
      justify-content: center;
      width: 100px;
    }
  }
}

