.popup[type=SelectPoint] {
  @include phone {
    .popup-content {
      padding-bottom:54px;
    }
  }
  .popup-inner {
    padding-bottom:25px;
    @include phone {
      padding-bottom:0;
    }
  }

  @include desktop {
    .mobile-bottom {
      padding-bottom: 2px;
    }  
  }
}