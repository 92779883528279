.pageNotFound {
  height: calc(100% - 102px);
  .wrapper {
    height:100%;
  }
  .cloud {
    position: relative;
    left: 50%;
    top: 43%;
    transform: translate(-50%,-50%);
    width:753px;
    text-align:center;
    max-width:100%;

    & > div {
      width:100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      color:$text_grey;
      padding-right: 40px;
      @include phone {
        padding-right: 0;
      }
    }

    img {
      width:100%;
    }

    @include phone {
      margin-top:34px;
      top:0;
      left:0;
      width:100%;
      transform:none;
    }

    h1 {
      font-size: 184px;
      line-height: 111%;
      @include phone {
        font-size: 64px;
      }
    }
    p {
      font-size: 24px;
      line-height: 111%;
      margin-top:-16px;

      @include phone {
        font-size: 14px;
      }
      a {
        color:$text_grey;
      }
    }
  }
}