.promotions {
  .filter {
    .text-input {
      width: 35.95%;
      @include phone {
        width:100%;
      }
    }
    .form-item {
      margin-top:-17px;
      @include phone {
        margin-top:17px;
      }
    }
    .checkbox {
      margin-top:23px;
    }
    &-left {
      @include phone {
        margin-top:4px;
        padding-bottom:110px;
      }
      .inline {
        margin-top: 39px;
      }
      label {
        margin-right:20px;
        @include phone {
          margin-right:0;
        }
      }
    }
    .right {
      width:21%;
    }
  }
  .no-items {
    @include phone {
      margin-top:30px;
      display:block;
    }
  }
  .table {
    & + .mobile-bottom {
      @include phone {
        padding-bottom: 10px !important;
        .button {
          margin: 0;
          width:100%;
        }
      }
    }
  }
}