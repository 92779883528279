@include phone {
  #app {
    overflow:auto;
  }
  .mobile-popup {
    display:none !important;
    background:white;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100% !important;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: auto;
    border-radius: 0 !important;
    padding:22px 20px 20px !important;
    transform: none;
    margin:0 !important;
    &._show, &.popup {
      display:block !important;
    }
    &._wide {
      width: 100% !important;
    }
    &[status=loading] {
      &:before,&:after {
        position:fixed;
      }
    }
  }
  .mobile-bottom {
    position:fixed;
    width: 100% !important;
    padding: 10px 20px 20px !important;
    left:0;
    bottom:0;
    background:white;
    display: flex;
    flex-direction: column;
    z-index:10;
    &.free {
      width:100% !important;
    }

    .button {
      & + .button {
        margin-top:10px !important;
        margin-left:0 !important;
      }
    }
  }
  .scrollbar-track {
    width: 4px !important;
    opacity: 0.5 !important;
  }
}
