.boomerangNotActive {
  margin-top:8px;
  @include phone {
    margin-top:0;
  }

  .boomerang-text {
    width:67.88%;
    padding-right:20px;
    line-height:25px;

    @include desktop-lower {
      width:50%;
    }
    @include phone {
      width:100%;
      padding-right:0;
      margin-top:29px;
    }

    p + p {
      margin-top:25px;
    }
    h3 {
      margin-top:25px;
      font-size:18px;
    }
    ul {
      margin-top:16px;
      li {
        padding-left:30px;
        position:relative;
        &:before {
          content:'';
          position:absolute;
          left:0;
          top:12px;
          width:21px;
          height:1px;
          background:$light_grey;
          border-radius: 2px;
        }
        & + li {
          margin-top:25px;
        }
      }
    }
  }
  .boomerang-request {
    position:relative;
    width:32.12%;
    box-shadow: 0 0 8px 3px rgba(79, 110, 149, 0.1);
    border-radius: 18px;
    background:white;
    padding:18px 30px 27px;

    @include desktop-lower {
      width:50%;
    }
    @include phone {
      width:100%;
    }

    h4 {
      line-height:16px;
    }
    .form-item {
      margin-top:9px;
    }
    .inline {
      margin-top:30px;
    }
    .error {
      margin-top:30px;
    }
  }
}