.popup[type=poll] {
  .mobile-bottom {
    margin-top:32px;
  }

  p {
    strong {
      display:block;
      padding-bottom:14px;
    }
  }
  .form-item {
    margin-top:5px;
  }

  .popup-inner {
    @include phone {
      padding-bottom: 98px;
    }
  }

  .question {
    margin-top:30px;
    width:calc(100% + 20px);

    @include phone {
      margin-top:2px;
      margin-left:0;
      width:100%;
    }

    h1 {
      margin-top:20px;
      width:calc(50% - 20px);
      font-size: 18px;
    }

    .answers {
      display: flex;
      margin-left: -20px;
      flex-direction: column;
    }

    .answer {
      margin-left:20px;
      margin-right:20px;
      margin-bottom: 8px;

      @include phone {
        margin-left:0;
        width:100%;
      }

      input {
        display:none;
        &:checked {
          & + label {
            background:rgba($green, .3);
            &:after {
              transform:scale(1)
            }
          }
        }
      }
      label {
        display:block;
        padding:10px 20px 10px 57px;
        font-size:14px;
        line-height:20px;
        border-radius: 18px;
        cursor:pointer;
        position:relative;
        &:before {
          content:'';
          position:absolute;
          left:20px;
          width:24px;
          height:24px;
          top:8px;
          border-radius:50%;
          border: 1px solid $light_grey;
          box-sizing: border-box;
          background:white;
          transition:0.25s;
        }
        &:after {
          content:'';
          position:absolute;
          transition:0.25s;
          transform:scale(0);
          top:14px;
          left:26px;
          width:12px;
          height:12px;
          background:$green;
          border-radius:50%;
        }
        @include hover {
          background:rgba($green, .1);
          &:before {
            background:$active_field;
          }
        }
      }
    }
  }
}
